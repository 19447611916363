import libraryReducer from "./libraryReducer";
import loginReducer from './loginReducer';
import errorReducer from './errorReducer';
import notesReducer from './notesReducer';
import dashboardReducer from './dashboardReducer';
import testReducer from './testReducer';
import jobsReducer from './jobsReducer';
import feedsReducer from './feedsReducer';
import syllabusReducer from './syllabusReducer';
import ContentReducer from './manageContentReducer';
import userReducer from './userReducer';
import accountReducer from './accountReducer';
import sideBarReducer from './sideBarReducer';
import noticeReducer from './noticeReducer';
import myContentReducer from './myContentReducer';
import achieverReducer from './achieverReducer';
import notificationReducer from './notificationReducer';
import profileReducer from './profileReducer';
import reportReducer from './reportReducer';
import SkillLibReducer from './skillLibReducer';
import SearchReducer from './searchReducer';
import { combineReducers } from "redux";
import connectReducer from "./connectReducer";




export const rootReducer = combineReducers({
    login: loginReducer,
    library: libraryReducer,
    dashboard: dashboardReducer,
    notes: notesReducer,
    test: testReducer,
    jobs: jobsReducer,
    feeds: feedsReducer,
    error: errorReducer,
    syllabus: syllabusReducer,
    content: ContentReducer,
    user: userReducer,
    account: accountReducer,
    sidebar: sideBarReducer,
    notice: noticeReducer,
    mycontent: myContentReducer,
    achiever: achieverReducer,
    notification: notificationReducer,
    profile: profileReducer,
    report: reportReducer,
    skillLibrary: SkillLibReducer,
    search: SearchReducer,
    connect: connectReducer
});

